@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.CampaignFiles {
  background-color: transparent;
  .detail-row {
    display: flex;
    flex-direction: row;
  }

  .detail-row-header {
    display: flex;
    flex-direction: row;
    background-color: $black;
    color: $white;
  }

  .material-field {
    // min-width: 8rem;
    // max-width: 15rem;
    width: 18rem;
    padding: 0.5rem;
    word-wrap: break-word;
    font-size: 0.75rem;
    text-align: center;
  }

  .image-thumbnail {
    border: 2px solid #C0C0C0;
    padding: 5px;
    margin-top: 1rem;
    max-width: 10rem;
  }
}