@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.WorkOrderDetails {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .right-content{
    width: 100%;
    float: right;
    width: calc(100% - #{$left-menu-width-colapsed});
    background-color: $app-background;

    @include md {
      width: calc(100% - #{$left-menu-width});
    }
  }

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 90vh;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .vendor-details {
    margin-bottom: 0.5rem;
  }

  .vendor-name h3 {
    color: $black;
    // border-bottom: 1px solid $gold-puma;

  }

  .store-details {
    border-bottom: 2px dotted $gold-puma;
    margin-bottom: 1rem;
  }

  .store-details h4 {
    color: $gold-puma;
  }

  .detail-row-header {
    display: flex;
    flex-direction: row;
    background-color: $black;
    color: $white;
    padding: 0px;
    margin: 0px;
    width: 100%;
    border-radius: 5px;
  }

  .material-field-header {
    width: 18rem;
    padding: 0.5rem;
    word-wrap: break-word;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .bg-gray {
    background-color: #F0F0F0;
    border-radius: 5px;
  }

  .total-amount {
    float: right;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0.5rem;
  }
}