@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.StoreMaterialList {
  background-color: transparent;
  padding: 1rem;
  width: 100%;
  height: 100%;

  .material-name {
    margin-left: 0.3rem;
    color: $gold-puma;
    font-weight: 600;
    font-size: 0.8rem;
  }
  .material-description {
    font-size: 0.7rem;
    font-weight: 400;
    color: $black;
  }
  .btn-add-material {
    background-color: transparent;
    // color: $white;
    border: 1px solid $black;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
  }
  .btn-add-material:hover {
    opacity: 0.7;
  }
  .info-icon {
    margin-left: 0.5rem;
    color: $gold-puma;
  }
  /* Tooltip */
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tiptext {
      visibility: hidden;
      width: 15rem;
      background-color: white;
      color: $black;
      text-align: center;
      border: 1px solid $black;
      border-radius: 5px;
      padding: 0.5rem;
      position: absolute;
      z-index: 1;
      margin-left: -60px;
      bottom: 150%;
      left: 50%;
  }
  .tooltip .tiptext::after {
      position: absolute;
      border-width: 5px;
      border-style: solid;
      margin-left: -5px;
      top: 100%;
      left: 50%;
  }
  .tooltip:hover .tiptext {
      visibility: visible;
  }

  .btn-add-permission-file {
    background-color: $black;
    color: $white;
    border: 1px solid $black;
    border-radius: 5px;
    padding: 5px;
    font-size: small;
  }

  .checked-all {
    margin-bottom: 0.5rem;
    color: $black;
    margin-left: 0.5rem;
  }
}