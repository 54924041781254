@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.CampaignDetails {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;

  .right-content{
    width: 100%;
    float: right;
    width: calc(100% - #{$left-menu-width-colapsed});
    background-color: $app-background;

    @include md {
      width: calc(100% - #{$left-menu-width});
    }
  }

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 90vh;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .buttons-container {
    margin-top: 20px;
    gap: 20px;

    & > .Button {
        max-width: 240px;
    }
  }

  .form-select {
    background-color: #F8F8F8;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 26px;
    justify-content: center;
    position: relative;
    width: 100%;
    border: 1px solid $black;
  }

  .store-name {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .store-selected {
    color: $gold-puma !important;
    font-size: 1.1rem;
  }

  .material-list {
    border-bottom: 1px solid $black;
  }

  .store-list {
    border-bottom: 1px solid $black;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: -1.5rem;
    max-height: 10rem;
    overflow-y: auto;
  }

  .select-selected {
    float: right;
    color: $gold-puma;
  }
  .menu-icon-close {
    color: $danger-color;
    margin-right: 1rem;
  }

  .store-card {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid $black;
  }
  .subtitle {
    font-size: 1rem;
    font-weight: 700;
  }
}