@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SideMenu{
    width: $left-menu-width-colapsed;
    height: 100vh;
    position: fixed;
    background-color: $white;
    box-shadow: -3px 0 10px 0 #555;
    float: left;
    font-family: Popppins-Regular;
    overflow-y: auto;

    @include md {
        width: $left-menu-width;
    }

    .menu-button{
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: $light-green;
        z-index: 999;
        color: $base-green;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 30px;
        height: 30px;
        // border: 1px solid green;
    }

    .title{
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        display: none;

        .logo{
            width: 114px;
        }

        @include md {
            display: flex;
        }
    }

    .list-menu-items{
        &--margin-top{
            margin-top: 75px;
        }
    }
    
    & .sidebar{
        position: fixed;
        height: 100%;
        width: $left-menu-width-colapsed;
        
        @include md {
            width: 220px; 
        }

        /*background-color: red;*/
        // color: black;

        & ul {
            list-style-type: none;
            position: relative;
            width: $left-menu-width-colapsed;
            padding-left: 0px;
            min-height: 50vh;
            height: 70vh;
            overflow-y: auto;
            padding-bottom: 8vh;

            @include md {
                width: 194px;
                padding-left: 22px;
            }
        }

        & li {
            position: relative;
            margin-top: 7px;
            margin-bottom: 7px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:hover{
                background-color: $dark;
                color: $orange;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;
            }
        }

        & li a {
            text-decoration: none;
            display: block;
            padding: 5px 10px;
            transition: all 0.2s ease-in-out;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
            color: $dark;
            font-family: Popppins-Regular;
            height: 37px;

            &:hover{
                background-color: $grayscale-disabled;
                color: $black;
                font-weight: bold;
                border-radius: 5px;
            }
        }

        .menu-item-text {
            padding-left: 10px;
            display: none;

            @include md {
                height: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }

        .menu-item{
            text-align: center;
            border-radius: 5px;
            
            @include md {
                text-align: left;
            }

            &--active{
                background-color: $grayscale-disabled;
                color: $black;
    
                & > a {
                    color: $black;
                    font-weight: 500;
                }

                :not(.Indicator):before {
                    width: 4px;
                    height: 30px;
                    background: $gold-puma;
                    content: '';
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    display: block;
                    position: absolute;
                    top: 5px;
                    left: -22px;
                }
            }
            &--active:hover {
                background-color: $white !important;
                color: $dark !important;
            }
        }

        .menu-item:hover {
            background-color: $white;
            color: $gold-puma;
            opacity: 10;
        }

        // Submenu
        & li ul {
            display:none;
            position:relative;
            min-width:140px;
            height: 10rem;
            min-height: 50px;
        }
        
        & li:hover > ul {
            display:block;
        }
        
        & li ul li {
            position:relative;
            margin-top: 7px;
            margin-bottom: 7px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;

            &:hover{
                background-color: $dark;
                color: $orange;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;
            }
        }

        & li ul li a {
            text-decoration: none;
            display: block;
            padding: 5px 10px;
            transition: all 0.2s ease-in-out;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
            color: $dark;
            font-family: Popppins-Regular;
            height: 37px;

            &:hover{
                background-color: $grayscale-disabled;
                color: $black;
                font-weight: bold;
                border-radius: 5px;
            }
        }
        
        & li ul li ul {
            right:-140px;
            top:0px;
        }
    }

    .menu-item-row{
        & > span {
            vertical-align: middle;
        }
    }

    .Indicator{
        position: absolute;
        top: 10px;
        right: 0;
    }

    .icon-container{
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 30px;
        height: 30px;

        & > img{
            margin: auto auto;
        }
    }

    .get-help{
        margin-left: 10px;
        
        &--active{
            & > * > .menu-item-text {
                color: $orange;
            }
        }

        & > a > span{
            font-family: Popppins-Regular;
            margin-left: 10px;
            color: $text-grey;
            font-weight: 400;
            font-size: 12px;
        }

        @include md {
            margin-left: 40px;
        }
    }

    #logout_sidebar_button {
        position: absolute;
        display: inline-block;
        bottom: 0;
        margin-bottom: 1rem;
        width: 80%;
        text-align: center;
        padding: 0.5rem;
        color: $white;
        cursor: pointer;
        border-radius: 5px;
        margin-left: 10%;
        background-color: $black;
        border-radius: 0px;
    }

    .menu-icon {
        color: $black;
        font-size: large;
    }

    .menu-icon-active {
        // color: $black;
        // font-size: large;
        display: block;
        @include md {
            display: none;
        }
    }

    .logout-text {
        background-color: white;
    }
}