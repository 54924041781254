@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.AddPermissionToStore {
  .file-name-preview {
    padding: 0.5rem;
    text-align: left;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .file-row {
    border-bottom: 1px solid $black;
  }
}