@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.QuoteDetails {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;

  .right-content{
    width: 100%;
    float: right;
    width: calc(100% - #{$left-menu-width-colapsed});
    background-color: $app-background;

    @include md {
      width: calc(100% - #{$left-menu-width});
    }
  }

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 90vh;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .field-name {
    font-size: 1rem;
    font-weight: 700;
    color: $gold-puma;
  }

  .material-row {
    border-bottom: 2px solid $gold-puma;
    padding-bottom: 1rem;
  }

  .form-select {
    background-color: #F8F8F8;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 36px;
    justify-content: center;
    position: relative;
    width: 10rem;
    border-color: #F8F8F8;
    border: 1px solid $black;
    min-width: 5rem;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .quote-stores {
    width: 100%;
    min-height: auto;
    padding: 0px;
    margin: 0px;
  }

  .store-name {
    font-size: 1.2rem;
    font-weight: 700;
    color: $gold-puma;
    padding: 0.2rem 0rem;
  }

  .detail-row-header {
    display: flex;
    flex-direction: row;
    background-color: $black;
    color: $white;
    padding: 0px;
    margin-top: 0.2rem;
    width: 100%;
    // border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: sticky;
  }

  .material-field-header {
    // min-width: 8rem;
    // max-width: 15rem;
    width: 11rem;
    padding: 0.5rem;
    word-wrap: break-word;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .section-title-campaign {
    width: 30%;
    word-wrap: break-word;
  }

  .subtotal {
    width: auto;
    // word-wrap: break-word;
    text-align: left;
  }

  .store-group-row {
    border: 1px solid $black;
    border-radius: 5px;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
  }

  .store-name-row {
    border: 1px solid $gold-puma;
    border-radius: 5px;
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .store-name-row-active {
    background-color: $gold-puma;
    color: $white;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .material-field-total {
    width: 33%;
    margin: 0.5rem;
  }

  .table td{
    width: auto;
    border: 1px solid $gold-puma;
    padding: 0.5rem;
  }

  .cell-label {
    background-color: $gold-puma;
    color: $white;
  }

  .cell-amount {
    text-align: right;
    font-weight: 600;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .font-bold {
    font-weight: bold;
  }
}