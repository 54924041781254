@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MaterialDetailRow {
  width: 100%;
  height: auto;
  border-left: 1px solid $black;
  border-right: 1px solid $black;
  border-bottom: 1px solid $black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.5rem;
  overflow-x: auto;

  .detail-row {
    display: flex;
    flex-direction: row;
  }

  .material-field {
    // min-width: 8rem;
    // max-width: 15rem;
    width: 11rem;
    padding: 0.5rem;
    word-wrap: break-word;
    font-size: 0.75rem;
    text-align: center;
  }

  .input-field {
    max-width: 6rem;
    border-radius: 5px;
    border: 1px solid $black;
    padding: 0.2rem;
  }

  .menu-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    padding-top: 0.2rem;
  }

  .icon-red {
    color: $danger-color;
  }

  .icon-puma {
    color: $gold-puma;
  }

  .border-error {
    border-color: $danger-color;
  }

  .border-success {
    border-color: $gold-puma;
  }
}