.AddMaterialToStore {
	.form-select {
    background-color: #F8F8F8;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 36px;
    justify-content: center;
    position: relative;
    width: 100%;
    border-color: #F8F8F8;
  }
}