@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.SearchTextbox {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: $margin-base;
    width: 100%;
    max-width: 732px;
    border-radius: 8px;
    border: 1px solid $gold-puma;  

    
    
    @include lg {
        /*width: 411px;*/
    }

    .input{
        position: relative;
        -webkit-appearance: none !important;
        background-clip: padding-box;
        vertical-align: middle;
        border-radius: 0.25rem;
        border: 0;
        font-size: 1rem;
        width: 100%;
        line-height: 2;
        padding: 0.375rem 1.25rem;
        -webkit-transition: border-color 0.2s;
        -moz-transition: border-color 0.2s;
        transition: border-color 0.2s;
        background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95% center;

        &:focus{
            outline: none;
            border-color: $base-green;
        }

        .input{
            background-color: white;
            height: 36px;
            border: 1px solid #EDEDED;
        }
    }


}