@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MaterialsModal {
	.pill-danger {
		background-color: red;
		border-radius: 50%;
		color: white;
		width: 3rem;
		height: 3rem;
		font-weight: 700;
		text-align: center;
		padding: 0.5rem;
	}
	.pill-warning {
		background-color: yellow;
		border-radius: 50%;
		color: black;
		width: 3rem;
		height: 3rem;
		font-weight: 700;
		text-align: center;
		padding: 0.5rem;
	}

	table td {
		border-bottom: 1px solid black;
	}
}