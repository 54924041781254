@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.InstallationRow {
  padding: 1rem;
  border-left: 1px solid $black;
  border-right: 1px solid $black;
  // border-bottom: 1px solid $black;
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;

  .input-field {
    max-width: 6rem;
    border-radius: 5px;
    border: 1px solid $black;
    padding: 0.2rem;
  }
}