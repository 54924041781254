@import "../../../../../assets/styles/colors";


.TideReactTable-Pagination{
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @media screen and (max-width: 700px) {
    text-align: center;
    margin: 0 auto;
  }

  .page-size-block{
    display: inline-block;
    .page-select{
      border-radius: 5px;
      border: 1px solid lightgrey;
      background: white;
      padding: 5px 10px;
      margin: 0 10px;
    }
  }
  .actual-rows{
    display: inline-block;
  }
  .pagination-btn{
    display: inline-block;
    min-width: 2.2rem;
    min-height: 2.2rem;
    margin: 0 7px;
    padding: 0;
    border: none;
    cursor: pointer;
    background: none;
    &.pag-ctrl{
      font-size: 1.4em;
    }
    &.active{
      color: white;
    }
  }

  .pageButton{
    background-color: $black;
    color: white;
    width: 33px;
    height: 33px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;

    & > svg {
      width: 13px;
      height: auto;
      
      & path{
        fill: white;
      }
    }

    &--disabled{
      background-color: $text-grey-light;
      color: $text-grey-light;
      cursor: not-allowed;

      & > svg {
        path{
          fill: initial;
        }
      }
    }

    &--page{
      background-color: white;
      border: 1px solid $gold-puma;
      color: $black;
    }

    &--active{
      background-color: $gold-puma;
      color: $white;
      cursor: default;
    }

    &--triangle{
      position: relative;
      overflow: hidden;
      padding-bottom: 10px;
      font-size: 20px;
      cursor: initial;

      &:after{
        position: absolute;
        bottom: -14px;
        height: 26px;
        width: 22px;
        right: -9px;
        background: $base-green;
        content: "";
        z-index: 1000;
        transform: skew(-45deg);
      }
    }
  }

  .middle-button-container{
    position: relative;
  }

  .pagination-select-page{
    width: 300px;
    height: 50px;
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;

    & > select {
      width: 150px;
      height: 36px;
      top: -2px;
      border: 1px solid $base-green;
      color: $base-green !important;
    }
  }
}
