@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Reports {
  background-color: #E5E5E5;
  width: 100%;
  height: 100%;

  .right-content{
    width: 100%;
    float: right;
    width: calc(100% - #{$left-menu-width-colapsed});
    background-color: $app-background;

    @include md {
      width: calc(100% - #{$left-menu-width});
    }
  }

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 90vh;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .date-input {
    background-color: $grayscale-light;
    border-radius: $border-radius-md;
    padding: 0.5rem 1rem;
    display: flex;
    // flex-direction: column;
    // margin-bottom: 20px;
    min-height: 1rem;
    justify-content: center;
    position: relative;
  }

  .report-content {
    border: 1px solid black;
    margin-top: 1rem;
    padding: 50px 20px;
  }

  .report-item {
    border: 1px solid black;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .excel-icon {
    color: green;
    font-size: 1.5rem;
  }
}