@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.Button{
  display: flex;
  border: none;
  background-color: $gold-puma;
  border-radius: 8px;
  padding: 12px 24px;
  color: white;
  font-family: Nunito-ExtraBold, sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.33em;
  $hover-time: 0.4s;
  transition: background-color $hover-time, color $hover-time, opacity $hover-time;
  path{
    transition: fill $hover-time;
  }
  &.basic:hover{
    opacity: 0.5;
  }

  &.clear{
    color: $black;
    background-color: $white;
    border: 1px solid $black;
    &:hover{
      color: $white;
      background-color: $gold-puma;
      opacity: 0.5;
      path{
        fill: white;
      }
    }
  }

  &.gray{
    background-color: $grayscale-medium2;
    color: white;
    &:hover{
      background-color: $grayscale-light2;
    }
  }

  &.danger{
    background-color: $red-light;
    color: white;
    &:hover{
      background-color: $red-dark;
    }
  }

  &.secondary{
    color: $white;
    background-color: #181818;
    border: 1px transparent;
    &:hover{
      background-color: rgba(24, 24, 24, 0.5);
    }
  }

  &.link {
    background-color: transparent;
    color: $base-green;
    &:hover{
      color: $base-green;
    }
  }
}
