@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.WorkOrderMaterials {
  .material-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: $black;
    color: $white;
    font-size: 1rem;
    font-weight: 700;
  }
}