@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.StoreAlertExpired {
  .store-row {
    margin-bottom: 1.5rem;
  }
  .store-name {
    font-size: 1rem;
    font-weight: 700;
    border-bottom: 1px solid $black;
    padding-bottom: 0.5rem;
  }
  .material-row {
    margin-top: 0.5rem;
  }
  table {
    width: 100%;
  }
  th, td {
    text-align: left;
    padding: 0.5rem;
    word-break: normal;
  }
  .mr-4 {
    margin-right: 1rem;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .date-input {
    background-color: $grayscale-light;
    border-radius: $border-radius-md;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 1rem;
    justify-content: center;
    position: relative;
  }
}
