@import "src/assets/styles/colors";
@import "src/assets/styles/measures";

.SectionTitle{
    font-size: 27px;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: $black;
    padding-left: 1rem;
}