@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.UsersForm {
  width: 100%;
  height: 100%;

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .form-select {
    background-color: #F8F8F8;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 40px;
    justify-content: center;
    position: relative;
    width: 100%;
    border-color: #F8F8F8;
  }

  .margin-top-mobile {
    margin-top: 2*$margin-base;

    @media screen and (min-width: 991px) {
      margin-top: 0;
    }        
  }

  &__buttonsContainer{
    margin-top: 60px;
    margin-bottom: 60px;

    .Button{
      min-width: 240px;
    }
  }

  .buttons-container {
    margin-top: 20px;
    gap: 20px;

    & > .Button {
      max-width: 240px;
    }
  }

  &__container {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @include md {
      flex-direction: row;    
    }
  }
}