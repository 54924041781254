@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.LabelStatus{
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin: 0 auto;*/

    background-color: white;
    border: 1px solid black;
    color: black;

    &--Pendiente, &--REGISTRY_STATUS_PENDING, &--STATUS_PENDING{
        background-color: transparent;
        color: $black;
        border: 1px solid $gold-puma;
    }

    &--Entregado, &--REGISTRY_STATUS_PRODUCTOR{
        background-color: transparent;
        color: $black;
        border: 1px solid $gold-puma;
    }   
    
    &--REGISTRY_STATUS_PROSPECT{
        background-color: $prospect-background-color;
        color: $prospect-color;
        border: 1px solid $prospect-color;
    }   
}