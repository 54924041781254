@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MaterialsModal {
  width: 100%;
  height: auto;

  .name {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .description {
    font-size: 0.8rem;
    word-break: break-all;
    font-weight: 700;
  }

  .material-row {
    border-bottom: 2px solid $gold-puma;
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $black;
    padding: 0.2rem;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    height: 6rem;
  }

  label {
    color: $gold-puma;
    font-size: 1rem;
    font-weight: 500;
  }
}