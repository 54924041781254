@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.UserInfo{
    width: 100%;
    max-width: 314px;
    margin-left: 26px;
    border-left: 1px solid $grayscale-light2;
    padding-left: 26px;
    display: none;

    @include md {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__name{
        font-size: 14px;
        font-weight: bold;
    }

    &__role{
        font-size: 12px;
        color: $text-grey-disabled;
    }

    &__picture{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-width: 40px;
        min-height: 40px;
        border: 1px solid $dark;
        padding: 0.5rem;

        > img {
            height: 90%;
        }
    }
}