@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.UserAvatar {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    
    &__avatar-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;

        &--empty {
            background-color: $grayscale-medium2;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
        }
    }

    &__user-name {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .details-link {
        color: $orange;
        cursor: pointer;
    }

    &__name-and-role {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .cursor-not-allowed {
        cursor: not-allowed;
    }
}