@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Users {
  width: 100%;
  height: 100%;

  .main-content  {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include md {
      padding-right: 50px;
      padding-left: 30px;
      padding-top: 0;
      padding-bottom: 30px;
    }
  }

  .margin-top-mobile {
    margin-top: 2*$margin-base;

    @media screen and (min-width: 991px) {
      margin-top: 0;
    }        
  } 

  .actions-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .settings-button {
    color: $base-green;
    font-size: 16px;
  }

  tr {
    color: $text-grey-light;
  }

  .permissions-title {
    font-size: 20px;
  }

  .user-permissions-description {
    color: $text-grey-light;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .m-0 {
    margin: 0;
  }

  .p-0 {
    padding: 0;
  }

  .SearchTextbox{
    margin: 0;     
    width: 100%;
    max-width: 500px;
  }

  .SearchTextbox .input {
    height: 36px;
    margin: 0;
  }

  .role-with-icon {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .manage-user-button {
    cursor: pointer;
  }
}