@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.ResultsCard{
    .Card{
        padding: 1rem;
    }

    &__main-title{
        font-size:1.2rem;
        font-weight: bold;
        color: $black;
        margin-bottom: 5px;
    }

    &__sub-title{
        font-size: 1rem;
        font-weight: 500;
        color: $black;
        padding: 0px;
        
        @include md {
            font-size: 14px;
        }

        @include xlg {
            font-size: 1rem;
        }
    }

    &__sub-title-mini{
        font-size: 14px;
        font-weight: bold;
        color: '#000';
        display: inline;
    }

    &__container{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;

        &--mt12{
            margin-top: 12px;
        }
    }

    &__left-content{
        display: flex;
        align-items: center;
        justify-content: center;
        /*margin-bottom: 14px;*/
        width: 90px;
        margin-right: 14px;
        flex-direction: column;
        font-size: 5rem;

        &.justify-type2{
            align-items: flex-start !important;
        }
    }

    &__right-content{
        width: 100%;
        flex: 1;

        &.justify-type2{
            text-align: right;
        }
    }

    & > .Card .bg-dark-green{
        background-color: $dark-green;
    }

    & .text-red{
        color: red;
    }

    & .text-green{
        color: #28B411;
    }
    & .bg-orange {
        background-color: $orange;
    }

    & .number-first {
        color: #524A65;
    }

    & .number-second {
        color: #EA5A06;
    }

    & .number-third {
        color: #006855;
    }

    & .number-fourth {
        color: #005CA9;
    }
}