@import "../../../assets/styles/colors";

.ResetPassword{
  .side-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
  }
  .form-container{
    width: 440px;
    max-width: 90%;
    margin-top: -100px;
  }
  .form {
    margin-top: 1rem;
  }
  .title{
    line-height: 3rem;
    margin-bottom: 1.5rem;
    color: #181818;
  }
  .subtitle{
    color: $gold-puma;
    font-family: Nunito-ExtraBold, sans-serif;
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  .forgot-section{
    text-align: right;
    margin: 36px 0 52px 0;
  }
  .submit, .google{
    width: 100%;
    margin-bottom: 30px;
  }
  .plus-icon{
    margin-right: 10px;
  }
  .sign-up-text{
    text-align: center;
    margin-top: 6px;
    font-family: Nunito-SemiBold, sans-serif;
  }
  .sign-up-link{
    font-family: Nunito-Bold, sans-serif;
    text-decoration: underline;
  }

  .right-side{
    align-items: end;
    @media only screen and (max-width: 768px){
      align-items: start;
      padding-top: 20px;
    }
  }

  .text-block{
    color: $orange;
    font-family: Nunito-Bold, sans-serif;
    font-size: 31px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.7);
    padding: 40px 35px;
    width: 90%;
    max-width: 505px;
    line-height: 1.03em;
    margin-bottom: 40px;
    border: 1px solid $orange;
    backdrop-filter: blur(6px);
    text-align: center;
    @media only screen and (max-width: 768px){
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .logo-img {
    width: 15rem;
    margin-bottom: 2rem;
  }
}