@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.StoresByGroup {
  border: 1px solid $gold-puma;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  
  .stores-row {
    width: 100%;
    height: auto;
    border: 1 px solid red;
  }

  .trash-icon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  .trash-icon-active {
    color: white;
  }
  .trash-icon-inactive {
    color: black;
  }

  .trash-icon:hover {
    color: red;
  }

  .active {
    background-color: $gold-puma;
    color: $white;
    padding: 1rem;
    border-radius: 5px;
    font-size: large;
  }

  .info-icon {
    font-size: 1.5rem;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }

  .mr-4 {
    margin-right: 1rem;
  }

  .stores-select {
    min-width: 30vw;
    max-height: 5vh;
  }

  .arrow-icon {
    width: 2vw;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .float-right {
    float: right;
  }

  .w-full {
    width: 100%;
  }

  .materials-info {
    font-size: 0.8rem;
    font-weight: 500;
    color: $black;
    text-align: left;
    padding-left: 0.5rem;
  }
}