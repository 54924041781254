@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";


.SimpleList {
    position: relative;

    background-color: white;
    padding: $margin-base;
    // border-radius: 8px;

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        .table {
            width: 100%;
        }
    }

    .table-container {
        min-height: 120px;
        padding: 0 5px;
        // border-radius: 8px;

        &-padding {
            padding: $margin-base;
        }
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 0;

        tr {
            // border-radius: 8px;
            background-color: white;
        }

        thead>tr {
            // border-radius: 8px;
        }

        thead>tr>th:first-child {
            border-left-style: solid;
            // border-top-left-radius: 8px;
            // border-bottom-left-radius: 8px;
            background-color: $black;
            color: $white;
        }

        thead>tr>th:last-child {
            border-left-style: solid;
            // border-top-right-radius: 8px;
            // border-bottom-right-radius: 8px;
            background-color: $black;
            color: $white;
        }

        thead>tr>th {
            background-color: $black;
            color: $white;
        }


        th,
        td {
            border-top: none;
            font-weight: normal;
            padding: 1rem;
            text-align: center;

            &:first-child {
                padding-left: 40px;
            }

            &:last-child {
                padding-right: 40px;
            }
        }

        th {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        td {
            border-top: none;
        }
    }  
}