@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.Modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;

    .back-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        backdrop-filter: blur(4px);
        background-color: rgba(39, 39, 39, 0.6);
    }

    .inner-modal {
        max-height: 95vh;
        background-color: white;
        border-radius: 15px;
        position: relative;
        padding: 24px;
        width: 611px;
        max-width: 98vw;
        overflow-y: auto;
    }

    &.large .inner-modal {
        width: 1400px;
    }

    .modal-title {
        display: inline-block;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $text-grey;
    }

    .close-btn {
        position: absolute;
        right: 24px;
        top: 18px;
        cursor: pointer;
    }

    .modal-content {
        padding: 20px 0;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        gap: 16px;

        .Button {
            width: 224px;
            max-width: 30vw;
        }
    }

    //For tables
    .table {
        border-collapse: separate;
        border-spacing: 0 0;
    
        tr {
            // border-radius: 8px;
            background-color: white;
        }
    
        thead>tr {
            // border-radius: 8px;
        }
    
        thead>tr>th:first-child {
            border-left-style: solid;
            // border-top-left-radius: 8px;
            // border-bottom-left-radius: 8px;
            background-color: $black;
            color: $white;
        }
    
        thead>tr>th:last-child {
            border-left-style: solid;
            // border-top-right-radius: 8px;
            // border-bottom-right-radius: 8px;
            background-color: $black;
            color: $white;
        }
    
        thead>tr>th {
            background-color: $black;
            color: $white;
        }
    
    
        th,
        td {
            border-top: none;
            font-weight: normal;
            padding: 1rem;
            text-align: center;
    
            &:first-child {
                padding-left: 40px;
            }
    
            &:last-child {
                padding-right: 40px;
            }
        }
    
        th {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }
    
        td {
            border-top: none;
        }
    }
}