@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.VendorWorkOrder {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  .vendor-row {
    border: 1px solid $black;
    padding: 0.5rem;
    border-radius: 5px;
  }
  .vendor-row-active {
    border: 1px solid $black;
    background-color: $gold-puma;
    padding: 0.5rem;
    border-radius: 5px;
    color: $white;
  }
  .store-row {
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid $black;
    border-radius: 5px;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }

  .active {
    border: 1px solid $gold-puma;
  }

  .grid-cols-4 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr;
    // grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }

  .bg-white {
    background-color: #ffffff;
  }
}