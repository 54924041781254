@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.MaterialEvidences {
  .form-select {
    background-color: #F8F8F8;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    height: auto;
    justify-content: center;
    position: relative;
    width: 100%;
    border-color: #F8F8F8;
  }

  .image-container-preview {
    padding: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .comment-row {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid black;
    padding-bottom: 0.5rem;
  }
}