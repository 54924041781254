@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.StoreAlertForm {
  width: 100%;
  height: 100%;

  .store-group-name {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .group-row {
    border: 1px solid $black;
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  .stores-row {
    border-bottom: 1px solid $black;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .select-all-label {
    font-size: 1rem;
    font-weight: 700;
    margin-right: 0.5rem;
    padding-top: 0.2rem;
  }
}