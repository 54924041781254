@import "src/assets/styles/colors";
@import "src/assets/styles/measures";
@import "src/assets/styles/breakpoints";
@import "src/assets/styles/mixins";

.CampaignFiles {
    background-color: #E5E5E5;
    width: 100%;
    height: 100%;
  
    .right-content{
      width: 100%;
      float: right;
      width: calc(100% - #{$left-menu-width-colapsed});
      background-color: $app-background;
  
      @include md {
        width: calc(100% - #{$left-menu-width});
      }
    }
  
    .main-content  {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 90vh;
  
      @include md {
        padding-right: 50px;
        padding-left: 30px;
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  
    .buttons-container {
      margin-top: 20px;
      gap: 20px;
  
      & > .Button {
          max-width: 240px;
      }
    }
  
    .form-select {
      background-color: #F8F8F8;
      border-radius: 8px;
      padding: 6px 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      min-height: 66px;
      justify-content: center;
      position: relative;
      width: 100%;
      border-color: #F8F8F8;
    }
  
    .store-name {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
    }
    .container-files {
      width: 100%;
      height: auto;
    }

    .image-container-preview {
      padding: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }

    .image-preview {
      width: 8rem;
      height: 10rem;
      margin-bottom: 2rem;

      & > img {
          width: 100%;
          height: auto;
          max-height: 10rem;
          border: 1px solid $black;
          border-radius: 5px;
      }
    }

    .trash-icon {
      color: $danger-color;
    }
}